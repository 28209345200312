import { AntDesign } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ResizeMode, Video } from 'expo-av';
import { useEffect, useRef } from 'react';
import { Animated, Image, StyleSheet, Text, View } from 'react-native';
import Button from './Button';
import HorizontalContainer from './common/Generic/HorizontalContainer';
import { BUTTON_LABEL_STYLE } from '/constants';

type Props = {
  onDismiss: () => void;
};

function MigrationPreAnnouncementBanner(props: Props) {
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    // Fade in on mount
    Animated.timing(opacity, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [opacity]);

  const onClose = () => {
    // Fade out before dismissing
    Animated.timing(opacity, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      props.onDismiss();
    });
  };

  return (
    <Animated.View
      style={[StyleSheet.absoluteFill, styles.container, { opacity }]}
    >
      <Video
        source={require('/assets/video/GiraffeHide.mp4')}
        usePoster
        resizeMode={ResizeMode.COVER}
        posterSource={require('/assets/images/giraffe-hide.webp')}
        posterStyle={{
          resizeMode: 'cover',
        }}
        videoStyle={{
          width: '100%',
          height: '100%',
        }}
        style={[StyleSheet.absoluteFill, { zIndex: -1 }]}
        shouldPlay
        isLooping
        isMuted
      />

      <View
        style={{
          flex: 1,
          alignSelf: 'flex-end',
          minWidth: 330,
          width: '50%',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            paddingHorizontal: 24,
            paddingRight: 32,
          }}
        >
          <Image
            source={require('/assets/images/keyFullWhite.png')}
            style={{
              width: 240,
              height: 240,
              margin: -36,
              marginBottom: -16,
            }}
          />

          <Text style={styles.heroText}>Something Big Is Coming</Text>
          <Text style={styles.heroSubtext}>THE KEY PLATFORM IS GROWING</Text>
        </View>

        <View
          style={{
            width: '100%',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            padding: 10,
            paddingHorizontal: 24,
            paddingLeft: 32,
            marginTop: 32,
            marginBottom: 28,
            alignSelf: 'flex-start',
            backgroundColor: '#0ee6a6',
            justifyContent: 'center',
            borderRadius: 8,
          }}
        >
          <Text
            style={{
              fontSize: 24,
              color: 'black',
              fontFamily: 'Lato-BoldItalic',
            }}
          >
            EXCITING UPDATES COMING JANUARY 2025!
          </Text>
        </View>

        <Button
          onPress={() => {
            AsyncStorage.setItem('has-seen-migration-pre-announcement', 'true');
            onClose();
          }}
          label={
            <HorizontalContainer>
              <Text
                style={[
                  BUTTON_LABEL_STYLE,
                  {
                    fontFamily: 'Lato-Bold',
                    fontSize: 18,
                  },
                ]}
              >
                ACCESS KEY
              </Text>
              <AntDesign
                style={{
                  marginLeft: 8,
                }}
                name="arrowright"
                size={24}
                color="black"
              />
            </HorizontalContainer>
          }
          containerStyle={{
            alignSelf: 'flex-start',
          }}
          style={{
            paddingVertical: 11,
            minWidth: 280,
          }}
        />
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 100,
    backgroundColor: 'black',
  },
  heroText: {
    fontSize: 56,
    color: 'white',
    fontFamily: 'FranklinGothic-Demi',
    maxWidth: 450,
  },
  heroSubtext: {
    fontSize: 18,
    color: 'white',
    fontFamily: 'Lato-Bold',
  },
});

export default MigrationPreAnnouncementBanner;
